import GitHubIcon from "@mui/icons-material/GitHub";
import LinkIcon from "@mui/icons-material/Link";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Avatar, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { TeamMemberLabels } from "../pages/Team";

interface Props {
    member: api_sub_team_route;
}

export default function TeamCard({ member }: Props) {
    const tag = member.tag;
    const size = "42px";

    return (
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Grid container>
                    <Grid item xs={2}>
                        {member.avatar && (
                            <Avatar alt={tag} src={member.avatar} sx={{ bgcolor: "#1e1e1e", width: size, height: size }}>
                                {tag}
                            </Avatar>
                        )}
                        {!member.avatar && (
                            <Avatar alt={tag.charAt(0)} sx={{ bgcolor: "#3498DB", width: size, height: size }}>
                                {tag.charAt(0)}
                            </Avatar>
                        )}
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h5" align="right">
                            {member.tag}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography sx={{ mb: 1.5 }} color="text.secondary" align="right">
                    {member.labels.map((label) => TeamMemberLabels[label]).join(", ")}
                </Typography>
            </CardContent>
            <CardActions>
                {member.socials && member.socials.url && (
                    <IconButton href={member.socials.url} target="_blank">
                        <LinkIcon />
                    </IconButton>
                )}
                {member.socials && member.socials.github && (
                    <IconButton href={member.socials.github} target="_blank">
                        <GitHubIcon />
                    </IconButton>
                )}
                {member.socials && member.socials.twitter && (
                    <IconButton href={member.socials.twitter} target="_blank">
                        <TwitterIcon />
                    </IconButton>
                )}
            </CardActions>
        </Card>
    );
}

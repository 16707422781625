interface Props {
    path: string;
}

function RedirectPage({ path }: Props) {
    // 👇️ redirect to external URL
    window.location.replace(path);

    return null;
}

export default RedirectPage;

import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box, Button, Container, Grid, IconButton, Link, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";

export default function Footer() {
    return (
        <Box
            component="footer"
            sx={{
                py: { xs: 3, sm: 4 },
                px: { xs: 3, sm: 4 },
                bgcolor: grey[900],
                color: "white",
                mt: "auto",
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Box borderBottom={1} sx={{ marginBottom: "10px" }}>
                            Start Tracking
                        </Box>
                        <Stack direction="column" alignItems="center" gap={1}>
                            <Button variant="outlined" href="/i">
                                Invite Uptime
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box borderBottom={1} sx={{ marginBottom: "10px" }}>
                            Socials
                        </Box>
                        <Stack direction={"row"}>
                            <IconButton href="https://twitter.com/UptimeOnDiscord" target="_blank" sx={{ "&:hover": { color: "primary.main" } }}>
                                <TwitterIcon />
                            </IconButton>
                            <IconButton href="https://github.com/TheUptimeProject" target="_blank" sx={{ "&:hover": { color: "primary.main" } }}>
                                <GitHubIcon />
                            </IconButton>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box borderBottom={1} sx={{ marginBottom: "10px" }}>
                            Help
                        </Box>
                        <Box>
                            <Link href="/support" color="inherit" underline="hover">
                                Support Server
                            </Link>
                        </Box>
                        <Box>
                            <Link href="/terms-of-service" color="inherit" underline="hover">
                                Terms of Service
                            </Link>
                        </Box>
                        <Box>
                            <Link href="/docs" color="inherit" underline="hover">
                                Documentation
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
                <Box textAlign="center" pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 0 }}>
                    &copy; 2018-{new Date().getFullYear()}{" "}
                    <Link color="inherit" underline="hover" href="/team">
                        The Uptime Project
                    </Link>
                </Box>
            </Container>
        </Box>
    );
}

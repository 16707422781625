import { Container, Grid, Skeleton, Typography } from "@mui/material";
import React from "react";
import TeamCard from "../components/TeamCard";
import { getAPIUrl } from "../util/helpers";

export enum TeamMemberLabels {
    DEVELOPER,
    ADMIN,
    STAFF,
}

const Team = () => {
    const [loaded, setLoaded] = React.useState(false);
    const [teamMembers, setTeamMembers] = React.useState<api_sub_team_route[]>([]);

    React.useEffect(() => {
        fetch(getAPIUrl("sub/int/team")).then((res) => {
            if (res.ok) {
                res.json().then((data: api_response<api_sub_team_route[]>) => {
                    if (data.success) {
                        setTeamMembers(data.data);
                        setLoaded(true);
                    } else {
                        console.error(data.message);
                        setLoaded(false);
                    }
                });
            }
        });
    }, [setLoaded]);

    return (
        <Container sx={{mt: "30px", mb: "50px"}}>
            <Typography variant="h1">The Team</Typography>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }} sx={{ mt: 3 }}>
                {loaded ? (
                    <>
                        {teamMembers.map((member) => {
                            return (
                                <Grid item xs={2} sm={4} md={4} key={member.snowflake}>
                                    <TeamCard member={member} />
                                </Grid>
                            );
                        })}
                    </>
                ) : (
                    <>
                        {Array.from(Array(9)).map((_, index) => (
                            <Grid item xs={2} sm={4} md={4} key={index}>
                                <Skeleton variant="rectangular" width={210} height={118} />
                            </Grid>
                        ))}
                    </>
                )}
            </Grid>
        </Container>
    );
};

export default Team;

import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Avatar, Box, Button, Container, IconButton, Link, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import { UptimeLogo } from "../components/icons/UptimeLogo";
import { UserContext } from "../context/UserContext";
import { getAPIUrl } from "../util/helpers";

interface Page {
    title: string;
    link: string;
}

const pages: Page[] = [
    {
        title: "Home",
        link: "/",
    },
    {
        title: "Documentation",
        link: "/docs",
    },
    {
        title: "Team",
        link: "/team",
    },
];

const settings: Page[] = [
    // { title: "Profile", link: "" },
    // { title: "Account", link: "" },
    // { title: "Dashboard", link: "" },
    { title: "Logout", link: "/logout" },
];

const Layout = () => {
    const { user, setUser } = useContext(UserContext);

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [loaded, setLoaded] = React.useState(false);

    useEffect(() => {
        fetch(getAPIUrl("sub/auth/user"), {
            credentials: "include",
        }).then((res) => {
            if (res.ok) {
                res.json().then((data: api_response<api_sub_auth_user_route>) => {
                    if (data.success) {
                        setUser(data.data);
                        setLoaded(true)
                    } else {
                        console.error(data.message);
                        setLoaded(true)
                    }
                });
            }
        });
    }, [setUser]);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <UptimeLogo sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: "none", md: "flex" },
                                fontFamily: "monospace",
                                fontWeight: 700,
                                letterSpacing: ".3rem",
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            UPTIME
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: "block", md: "none" },
                                }}
                            >
                                {pages.map((page) => (
                                    <Link href={page.link} key={page.link} underline="none">
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <Typography textAlign="center">{page.title}</Typography>
                                        </MenuItem>
                                    </Link>
                                ))}
                            </Menu>
                        </Box>
                        {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                mr: 2,
                                display: { xs: "flex", md: "none" },
                                flexGrow: 1,
                                fontFamily: "monospace",
                                fontWeight: 700,
                                letterSpacing: ".3rem",
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            UPTIME
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                            {pages.map((page) => (
                                <Link href={page.link} key={page.link} underline="none">
                                    <Button key={page.title} onClick={handleCloseNavMenu} sx={{ my: 2, color: "white", display: "block" }}>
                                        {page.title}
                                    </Button>
                                </Link>
                            ))}
                        </Box>

                        {(loaded && user) && (
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar src={user.avatar} alt={user.tag} sx={{ bgcolor: "#3498DB" }}>
                                            {user.tag}
                                        </Avatar>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: "45px" }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <Link href={setting.link} key={setting.link} underline="none">
                                            <MenuItem onClick={handleCloseUserMenu}>
                                                <Typography textAlign="center" sx={{color:"white"}}>{setting.title}</Typography>
                                            </MenuItem>
                                        </Link>
                                    ))}
                                </Menu>
                            </Box>
                        )}

                        {(loaded && !user) && (
                            <Button variant="contained" color="primary" href="/login">
                                Login
                            </Button>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>

            <Outlet />

            <Footer />
        </Box>
    );
};

export default Layout;

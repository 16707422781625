import CheckIcon from "@mui/icons-material/Check";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DnsIcon from "@mui/icons-material/Dns";
import PeopleIcon from "@mui/icons-material/People";
import PercentIcon from "@mui/icons-material/Percent";
import { Box, Button, Container, Grid, Paper, Skeleton, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { UptimeLogo } from "../components/icons/UptimeLogo";
import { getAPIUrl } from "../util/helpers";

const Home = () => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [stats, setStats] = useState<api_sub_stats_route>();

    useEffect(() => {
        // setTimeout(() => {
        //     setStats({ guild_count: 2711, user_count: 361646, id: "123456789", version: "1.0.0", commit_hash: "uiohwef", stored_user_count: 361646, shard_count: 3, operating_mode: 0, timestamp: new Date(), average_uptime: 123 });
        //     setLoaded(true);
        // }, 2000);
        fetch(getAPIUrl("sub/stats/latest")).then((res) => {
            if (res.ok) {
                res.json().then((data: api_response<api_sub_stats_route>) => {
                    if (data.success) {
                        setStats(data.data);
                        setLoaded(true);
                    } else {
                        console.error(data.message);
                        setLoaded(false);
                    }
                });
            }
        });
    }, [loaded, setLoaded]);

    return (
        <Box sx={{ overflowX: "hidden" }}>
            <Box sx={{ height: "75vh", width: "100vw", m: 0, p: 0 }}>
                <Box sx={{ height: "95vh", backgroundImage: "linear-gradient(rgba(18,18,18,0.1),rgba(18,18,18,0.6), rgba(18,18,18,1.0)), url(https://assets.brycecary.dev/uptime/v3/16x9-background-4k.png)" }} />
                <Stack sx={{ top: "50%", left: "50%", position: "absolute", transform: "translate(-50%,-50%)" }} direction={"column"} alignItems="center" gap={1}>
                    <UptimeLogo sx={{ fontSize: 175, boxShadow: 24, borderRadius: 100 }} />
                    <Typography
                        sx={{
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            fontSize: 84,
                        }}
                    >
                        UPTIME
                    </Typography>
                    <Stack direction="column" alignItems="center" gap={1}>
                        <Button variant="contained" href="/i" sx={{ boxShadow: 10 }} size="large">
                            INVITE
                        </Button>
                    </Stack>
                </Stack>
            </Box>
            <Container>
                {/* <Paper elevation={3} sx={{ height: "30px" }} /> */}

                <Paper sx={{ p: 4 }} elevation={4}>
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={6} md={2}>
                            <Stack direction="column" alignItems="center" gap={1}>
                                <DnsIcon color="primary" sx={{ fontSize: 62 }} />
                                <Typography variant="h4">{loaded ? stats?.guild_count : <Skeleton variant="text" animation="wave" width="50px" />}</Typography>
                                <Typography variant="h6">Servers</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Stack direction="column" alignItems="center" gap={1}>
                                <PeopleIcon color="primary" sx={{ fontSize: 62 }} />
                                <Typography variant="h4">{loaded ? stats?.stored_user_count : <Skeleton variant="text" animation="wave" width="50px" />}</Typography>
                                <Typography variant="h6">Users</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Stack direction="column" alignItems="center" gap={1}>
                                <PercentIcon color="primary" sx={{ fontSize: 62 }} />
                                <Typography variant="h4">{loaded ? `${stats?.average_uptime.toFixed(0)}%` : <Skeleton variant="text" animation="wave" width="50px" />}</Typography>
                                <Typography variant="h6">Average Uptime</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Stack direction="column" alignItems="center" gap={1}>
                                <DateRangeIcon color="primary" sx={{ fontSize: 62 }} />
                                <Typography variant="h4">{loaded ? stats?.years_logged.toFixed(0) : <Skeleton variant="text" animation="wave" width="50px" />}</Typography>
                                <Typography variant="h6">Years Logged</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Stack direction="column" alignItems="center" gap={1}>
                                <ContactSupportIcon color="primary" sx={{ fontSize: 62 }} />
                                <Typography variant="h4">Support</Typography>
                                <Typography variant="h6">With Speed</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <Stack direction="column" alignItems="center" gap={1}>
                                <CheckIcon color="primary" sx={{ fontSize: 62 }} />
                                <Typography variant="h4">Verified</Typography>
                                <Typography variant="h6">By Discord</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container sx={{ marginTop: "60px", marginBottom: "60px" }}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3" py="20px">
                            What is Uptime?
                        </Typography>
                        <Typography variant="body1" py="15px">
                            Uptime is a fully customizable Discord bot that tracks how much time of your life you spend glued to Discord.
                        </Typography>
                        <Button variant="contained" color="primary" href="/support">
                            Join Our Support Server
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3" py="20px">
                            Basic Commands
                        </Typography>
                        <Typography variant="body1" py="15px">
                            All Uptime commands use Discord's slash command system.
                        </Typography>
                        <Stack direction="column" alignItems="left" gap={1} sx={{ marginTop: "20px" }}>
                            <Typography variant="body2">
                                <code>/uptime</code> - <i>Get your uptime</i>
                            </Typography>
                            <Typography variant="body2">
                                <code>/theme</code> - <i>Make your uptime, your own</i>
                            </Typography>
                            <Typography variant="body2">
                                <code>/alarm</code> - <i>Receive a notification when pre-defined parameters are met</i>
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Home;

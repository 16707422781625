import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { DISCORD_BOT_INVITE, SUPPORT_SERVER_INVITE, TERMS_OF_SERVICE } from "./configs";
import UserContextProvider from "./context/UserContext";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import NoPage from "./pages/NoPage";
import RedirectPage from "./pages/RedirectPage";
import Team from "./pages/Team";
import { generateLoginRoute, generateLogoutRoute } from "./util/helpers";

function App() {
    return (
        <UserContextProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        {/* Documentation */}
                        <Route path="/docs" element={<RedirectPage path={"https://docs.adduptime.net"} />} />

                        {/* Team page */}
                        <Route path="/team" element={<Team />} />

                        {/* Bot invite */}
                        <Route path="/invite" element={<RedirectPage path={DISCORD_BOT_INVITE} />} />
                        <Route path="/i" element={<RedirectPage path={DISCORD_BOT_INVITE} />} />

                        {/* Terms of service */}
                        <Route path="/terms-of-service" element={<RedirectPage path={TERMS_OF_SERVICE} />} />
                        <Route path="/tos" element={<RedirectPage path={TERMS_OF_SERVICE} />} />

                        {/* Support Server */}
                        <Route path="/support" element={<RedirectPage path={SUPPORT_SERVER_INVITE} />} />

                        {/* Login & Logout */}
                        <Route path="/login" element={<RedirectPage path={generateLoginRoute()} />} />
                        <Route path="/logout" element={<RedirectPage path={generateLogoutRoute()} />} />

                        {/* 404 Page */}
                        <Route path="*" element={<NoPage />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </UserContextProvider>
    );
}

export default App;

export function getAPIBaseUrl() {
    return process.env.REACT_APP_API_BASE?.endsWith("/") ? process.env.REACT_APP_API_BASE : `${process.env.REACT_APP_API_BASE}/`;
}

export function getAPIUrl(path: string): string {
    if (path.charAt(0) === "/") {
        path = path.substring(1);
    }
    return `${getAPIBaseUrl()}${path}`;
}

export function generateLoginRoute() {
    return getAPIUrl("/sub/auth/login");
}

export function generateLogoutRoute() {
    return getAPIUrl("/sub/auth/logout");
}
